import React, { FC, useContext, useEffect, useState } from "react";
// eslint-disable-next-line no-restricted-imports
import { AspectRatio, Box, Flex, Heading, Text as ChakraText, Image as ChakraImage, As } from "@chakra-ui/react";
import { sizes as LinkSizes, getLinkVariants } from "~/foundation/Theme/components/Link";
import { imageSizes } from "~/foundation/Theme";
import LinkIconWrapper from "../Link/LinkIconWrapper";
import { mediaApi } from "~/foundation/Jss/index";
import { ChakraRouterLink } from "../Link/ChakraRouterLink";
import { dateFormatter } from "~/foundation/Utils/dateFormatter";
import { ColorThemeContext } from "~/foundation/Theme/ColorThemeContext";
import { Separator } from "../Separator";
import { LazyImage } from "../Image/LazyImage";
import { Icon } from "../Icon";
import { useTranslation } from "~/foundation/Dictionary";
import { GlobalDictionary } from "~/foundation/Dictionary/dictionary";

export type GeneratedCardModel = {
	url?: string;
	title?: string;
	teaser?: string;
	publishDate: (Date | string);
	displayPublishDate?: string;
	displayPublishMonth?: string;
	attributePublishDate?: string;
	location?: string;
	locationAndPublishDate?: string;
	imageSrc?: string;
	imageAlt?: string;
	articleType?: string;
	hasAudioFile?: boolean;
}

type GeneratedCardProps = {
	card: GeneratedCardModel,
	index: number,
	isRtl: boolean,
	pageEditing: boolean,
	useWithSplide: boolean,
	showContentType?: boolean,
	showLocation?: boolean,
	headerTag?: As,
}

export const GeneratedCard: FC<GeneratedCardProps> = ({ card, isRtl, pageEditing, useWithSplide, showContentType = true, headerTag = "h3" }) => {
	const [cardActive, setCardActive] = useState(false);
	const initialContentRatio = card.imageSrc ? 16 / 20 : 2 / 3;
	const [cardContentRatio, setContentCardRatio] = useState(initialContentRatio);
	const linkVariants = getLinkVariants(isRtl);
	let formattedDate = card.displayPublishDate;
	if (typeof card.publishDate === "string" && card.publishDate !== "") {
		formattedDate = dateFormatter(new Date(card.publishDate), isRtl);
	}

	const [theme] = useContext(ColorThemeContext);
	const [t] = useTranslation<GlobalDictionary>();

	const handleCardEvent = (activeState: boolean) => {
		setCardActive(activeState)

		if (card.imageSrc) {
			if (activeState) {
				setContentCardRatio(16 / 17);
			} else {
				setContentCardRatio(16 / 16);
			}
		} else {
			setContentCardRatio(2 / 3);
		}
	}

	useEffect(() => {
		handleCardEvent(cardActive);
	},[])

	return (
		<>
			{(card.url || pageEditing) &&
				<ChakraRouterLink
					variant="unstyled"
					display="block"
					w="100%"
					h="100%"
					to={card.url}
					{...(!pageEditing && {
						onMouseEnter: () => handleCardEvent(true),
						onMouseLeave: () => handleCardEvent(false),
					})}
				>
					<AspectRatio ratio={2 / 3}>
						<Box borderRadius="lg">
							<Flex
								direction="column"
								alignSelf="stretch"
								justifyContent={card.imageSrc ? "flex-end" : "flex-start" }
								minW="100%"
								bg={theme.colors.generatedCard.bg}
								position="relative">
								{card.imageSrc &&
									<AspectRatio ratio={16 / 9} position="absolute" top="0" w="100%">
										{useWithSplide ?
											<ChakraImage
												src={mediaApi.updateImageUrl(card.imageSrc, imageSizes.none)}
												sizes="(min-width: 992px) 427px, 315px"
												data-splide-lazy={card.imageSrc}
												data-splide-lazy-srcset={mediaApi.getSrcSet(card.imageSrc, [imageSizes.generatedCard.landscape.xs, imageSizes.generatedCard.landscape.md])}
												alt={card.imageAlt}
												maxW="100%"
											/>
											:
											<LazyImage
												src={mediaApi.updateImageUrl(card.imageSrc, imageSizes.none)}
												sizes="(min-width: 992px) 427px, 315px"
												srcSet={mediaApi.getSrcSet(card.imageSrc, [imageSizes.generatedCard.landscape.xs, imageSizes.generatedCard.landscape.md])}
												alt={card.imageAlt}
												maxW="100%"
											/>
										}
									</AspectRatio>
								}
								<AspectRatio
									ratio={cardContentRatio}
									bg={theme.colors.generatedCard.bg}
									color={theme.colors.generatedCard.color}
									borderRadius="lg"
									sx={{
										'&:before': {
											transitionProperty: "padding",
											transitionDuration: ".3s",
											transitionTimingFunction: "aramcoCubic"
										}
									}}>
									<Box>
										<Flex direction="column" alignSelf="stretch" p="6" flex="1">
											<ChakraText noOfLines={1} variant="overline" fontSize="xs" lineHeight="1.286">
												{(showContentType && card.articleType) &&
													<ChakraText as="span">{card.articleType}</ChakraText>
												}
												{formattedDate &&
													<>
														{showContentType && <Separator themed w="1.5rem" h=".5rem" />}
														<ChakraText as="span" variant="overline" fontSize="xs" lineHeight="1.286">
															<time dateTime={formattedDate} itemProp="dateModified">
																{formattedDate}
															</time>
															<meta itemProp="datePublished" content={formattedDate}/>
														</ChakraText>
													</>
												}
											</ChakraText>
											{card.title &&
												<Heading
													as={headerTag}
													size="xs"
													fontSize="1.25rem"
													lineHeight="1.75rem" //override h6 mobile to match h6 desktop
													mt="4"
													noOfLines={7}>
													{card.title}
												</Heading>
											}
											{card.hasAudioFile && (
												<Flex gap="2" alignItems="center" mt="4">
													<Icon variant="Headphones" w="1.25rem" h="1.25rem" mt="-1" color={theme.colors.generatedCard.color} />
													<ChakraText variant="footerBody">
														{t("general.labels.audio")}
													</ChakraText>
												</Flex>
											)}
											<Box display="inline-flex" flexGrow="1" alignSelf="flex-end" alignItems="end">
												<Box sx={linkVariants.primary} color={theme.colors.generatedCard.linkColor}>
													<Box className="icon-container" sx={LinkSizes.sm[".icon-container"]}>
														<LinkIconWrapper type="ArrowRight" w="icons.md" rtl={isRtl}/>
													</Box>
												</Box>
											</Box>
										</Flex>
									</Box>
								</AspectRatio>
							</Flex>
						</Box>
					</AspectRatio>
				</ChakraRouterLink>
			}
		</>
	);
};